import type { TRuleWithTheme } from '@ackee/fela';

export const extendContainer: TRuleWithTheme = ({ theme: { colors } }) => ({
    paddingTop: 0,
    borderBottom: `0.0625rem solid ${colors.lightGrey}`,

    tabletLarge: {
        paddingTop: 0,
    },
});

export const extendSectionTitle: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.black,
    marginBottom: '4.5rem',
});

export const button: TRuleWithTheme = ({ theme: { colors, transition } }) => ({
    border: 0,
    borderBottom: `0.125rem solid ${colors.blue}`,
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: colors.blue,
    padding: 0,
    margin: 'auto',
    display: 'block',
    cursor: 'pointer',

    transition: transition,

    ':hover': {
        color: colors.fuchsia,
    },
});
