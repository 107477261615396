import type { TRuleWithTheme } from '@ackee/fela';

export const extendContainer: TRuleWithTheme = ({ theme: { colors } }) => ({
    borderBottom: `0.125rem solid ${colors.dividerGrey}`,
    marginBottom: '4.2rem',

    animationName: {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
    },
    animationDuration: '0.5s',

    tablet: {
        borderBottom: 'none',
        borderLeft: `0.125rem solid ${colors.dividerGrey}`,
        paddingLeft: '1.5rem',
        paddingRight: '2.125rem',
        marginBottom: '5.5rem',
    },
});

export const extendTitle: TRuleWithTheme = ({ theme: { colors, transition } }) => ({
    fontSize: '1.5rem',
    marginBottom: '0',
    transition: transition,
    willChange: 'color, border-color',
    borderBottom: '1px solid transparent',
    cursor: 'none',

    tabletLarge: {
        display: 'inline',
    },

    ':hover': {
        color: colors.blue,
        borderBottom: `1px solid ${colors.blue}`,
    },
});

export const link: TRuleWithTheme = () => ({
    textDecoration: 'none',
    display: 'block',
    cursor: 'none',
});

export const extendMediaLogoContainer: TRuleWithTheme = () => ({
    marginTop: '2.5rem',
    paddingBottom: '1rem',
});
