import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';
import { FormattedDate } from 'react-intl';

import type { HoverContextState, SetHoverContextState } from 'modules/custom-cursor/context';
import { ContainerType, DynamicContainer, LocalizedLink, Text, TextTypes, Title } from 'modules/ui';
import { routes } from 'config/routes';

import type { ArticleCardProps } from '../ArticleCard';
import { MediaLogo } from '../../../MediaLogo';
import * as felaRules from './ArticleCardInner.rules';

export interface ArticleCardInnerProps extends ArticleCardProps {
    hoverContext: HoverContextState;
    setHoverContext: SetHoverContextState;
}

export const ArticleCardInner = ({
    title,
    publishedDate,
    mediaLogo,
    slug,
    hoverContext,
    setHoverContext,
}: ArticleCardInnerProps) => {
    const { rules, styles } = useFelaEnhanced(felaRules);

    const logo = mediaLogo?.formats?.small ?? mediaLogo;

    const handleHover = (value: boolean) => {
        setHoverContext({
            ...hoverContext,
            article: {
                ...hoverContext.article,
                hovered: value,
            },
        });
    };

    return (
        <DynamicContainer as={ContainerType.ARTICLE} extend={{ container: rules.extendContainer }} columns={4}>
            {/* @ts-expect-error */}
            <Text type={TextTypes.SECTION}>
                {publishedDate && <FormattedDate value={publishedDate} day="numeric" month="long" year="numeric" />}
            </Text>

            <LocalizedLink
                to={`${routes['/about-us']}/${slug}`}
                className={styles.link}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
            >
                {/* @ts-expect-error */}
                <Title extend={{ title: rules.extendTitle }}>{title}</Title>
            </LocalizedLink>

            {logo?.url ? (
                <DynamicContainer extend={{ container: rules.extendMediaLogoContainer }}>
                    <MediaLogo
                        src={logo?.url}
                        width={logo.width}
                        height={logo.height}
                        alt={mediaLogo?.alternativeText}
                    />
                </DynamicContainer>
            ) : null}
        </DynamicContainer>
    );
};
