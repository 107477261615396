import React, { forwardRef, type ForwardedRef, useState } from 'react';
import { useFelaEnhanced } from '@ackee/fela';
import { FormattedMessage } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';

import { ContainerType, DynamicContainer, Text, TextTypes, TitleTypes } from 'modules/ui';

import * as felaRules from './ArticlesList.rules';
import { ArticleCard } from './ArticleCard';

const INITIAL_ARTICLES_COUNT = 6;

export const ArticlesList = forwardRef((_, ref: ForwardedRef<HTMLElement>) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const [expanded, setExpanded] = useState(false);

    const {
        allStrapiPrArticle: { nodes: articles },
    } = useStaticQuery<Queries.AllStrapiPrArticlesQuery>(graphql`
        query AllStrapiPrArticles {
            allStrapiPrArticle(sort: { fields: publishedDate, order: DESC }) {
                nodes {
                    ...PrArticle
                }
            }
        }
    `);

    if (!articles.length) return null;

    return (
        <DynamicContainer extend={{ container: rules.extendContainer }} isFluid ref={ref} as={ContainerType.SECTION}>
            {/* @ts-expect-error */}
            <Text type={TextTypes.SECTION} as={TitleTypes.H2} extend={{ text: rules.extendSectionTitle }}>
                <FormattedMessage id="wroteAboutUs.title" />
            </Text>
            <DynamicContainer type={ContainerType.GRID}>
                {articles.slice(0, expanded ? undefined : INITIAL_ARTICLES_COUNT).map(article => (
                    <ArticleCard key={article.slug} {...article} />
                ))}
            </DynamicContainer>
            {!expanded && articles.length > INITIAL_ARTICLES_COUNT && (
                <button type="button" className={styles.button} onClick={() => setExpanded(true)}>
                    <FormattedMessage id="wroteAboutUs.showAllButton" />
                </button>
            )}
        </DynamicContainer>
    );
});
