import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import { ContainerType, DynamicContainer, Layout, Text, TextTypes, Title, TitleTypes } from 'modules/ui';
import { MarkdownContent } from 'modules/mdx';
import { Offices } from 'modules/offices';
import { InquirySection } from 'modules/inquiry';

import * as felaRules from './ArticlePage.rules';
import { MediaLogo } from '../MediaLogo';
import { ArticleCard } from '../ArticlesList/ArticleCard';

export interface ArticlePageProps extends Queries.StrapiPrArticleQuery {
    featuredArticles: Queries.PrArticleFragment[];
}

export const ArticlePage = ({ strapiPrArticle, featuredArticles }: ArticlePageProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    if (!strapiPrArticle) return null;

    const { excerpt, articleUrl, title, publishedDate, mediaLogo } = strapiPrArticle;
    const logo = mediaLogo?.formats?.small ?? mediaLogo;

    return (
        <Layout>
            <>
                <DynamicContainer isFluid type={ContainerType.GRID} as={ContainerType.ARTICLE}>
                    <DynamicContainer columns={8}>
                        {/* @ts-expect-error */}
                        <Text type={TextTypes.SECTION}>
                            {publishedDate ? (
                                <FormattedDate value={publishedDate} day="numeric" month="long" year="numeric" />
                            ) : null}
                        </Text>
                        {/* @ts-expect-error */}
                        <Title as={TitleTypes.H1} extend={{ title: rules.extendTitle }}>
                            {title}
                        </Title>
                        <div className={styles.linkContainer}>
                            {logo?.url ? (
                                <MediaLogo
                                    src={logo.url}
                                    alt={mediaLogo?.alternativeText}
                                    width={logo.width}
                                    height={logo.height}
                                />
                            ) : null}
                            {articleUrl ? (
                                <a target="_blank" rel="noopener noreferrer" className={styles.link} href={articleUrl}>
                                    <FormattedMessage id="wroteAboutUs.article.readFull" />
                                </a>
                            ) : null}
                        </div>
                        <div className={styles.excerpt}>
                            <MarkdownContent body={excerpt?.data?.childMdx?.body ?? ''} />
                        </div>
                    </DynamicContainer>
                    <DynamicContainer extend={{ container: rules.extendFeaturedArticlesContainer }} columns={4}>
                        <>
                            {featuredArticles.map(article => (
                                <ArticleCard key={article.id} {...article} />
                            ))}
                        </>
                    </DynamicContainer>
                </DynamicContainer>
                <div className={styles.divider} />
                <Offices />
                <InquirySection />
            </>
        </Layout>
    );
};

export const query = graphql`
    fragment PrArticleDetail on STRAPI_PR_ARTICLE {
        id
        title
        articleUrl
        slug
        publishedDate
        ...MediaLogo
        excerpt {
            data {
                childMdx {
                    body
                    excerpt
                }
            }
        }
        seo {
            metaDescription
            keywords
            metaTitle
            metaImage {
                url
                width
                height
            }
        }
    }
`;
