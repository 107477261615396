import type { TRuleWithTheme } from '@ackee/fela';

export const extendTitle: TRuleWithTheme = () => ({
    maxWidth: '95%',

    tabletLarge: {
        fontSize: '3.5rem',
    },
});

export const linkContainer: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '4.5625rem',
});

export const link: TRuleWithTheme = ({ theme: { colors, transition } }) => ({
    color: colors.blue,
    fontSize: '1.25rem',
    borderBottom: `0.125rem solid ${colors.blue}`,
    textDecoration: 'none',
    fontWeight: 'bold',

    transition,
    transitionProperty: 'color',

    ':hover': {
        color: colors.fuchsia,
    },
});

export const excerpt: TRuleWithTheme = () => ({
    maxWidth: '40.1875rem',
});

export const extendFeaturedArticlesContainer: TRuleWithTheme = () => ({
    marginTop: '4.25rem',
});

export const divider: TRuleWithTheme = ({ theme: { colors } }) => ({
    backgroundColor: colors.lightGrey,
    height: '1px',
    width: '100%',
});
