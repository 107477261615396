export const getFeaturedArticles = (
    currentArticle: Queries.PrArticleDetailFragment,
    allArticles: readonly Queries.PrArticleFragment[],
) => {
    const currentArticleIndex = allArticles.findIndex(({ id }) => id === currentArticle.id);

    if (currentArticleIndex === -1) return [];

    if (currentArticleIndex === 0) {
        return [allArticles[1], allArticles[2]].filter(Boolean);
    }

    if (currentArticleIndex === allArticles.length - 1) {
        return [allArticles[currentArticleIndex - 1], allArticles[currentArticleIndex - 2]].filter(Boolean);
    }

    return [allArticles[currentArticleIndex - 1], allArticles[currentArticleIndex + 1]].filter(Boolean);
};
