import React from 'react';
import { graphql } from 'gatsby';

import { HoverConsumer } from 'modules/custom-cursor';

import { ArticleCardInner } from './ArticleCardInner';

export interface ArticleCardProps extends Queries.PrArticleFragment {
    mediaLogo: Queries.MediaLogoFragment['mediaLogo'];
}
export const ArticleCard = (props: ArticleCardProps) => (
    <HoverConsumer>
        {render => <ArticleCardInner hoverContext={render.context} setHoverContext={render.setContext} {...props} />}
    </HoverConsumer>
);

export const query = graphql`
    fragment PrArticle on STRAPI_PR_ARTICLE {
        id
        title
        slug
        publishedDate
        ...MediaLogo
    }
`;
