import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';
import { graphql } from 'gatsby';

import * as felaRules from './MediaLogo.rules';

export interface MediaLogoProps {
    src: string;
    alt?: string | null;
    width?: number | null;
    height?: number | null;
}

export const MediaLogo = ({ src, alt, width, height }: MediaLogoProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <img
            className={styles.logo}
            src={src}
            alt={alt ?? ''}
            width={width ?? undefined}
            height={height ?? undefined}
        />
    );
};

export const query = graphql`
    fragment MediaLogo on STRAPI_PR_ARTICLE {
        mediaLogo {
            alternativeText
            url
            width
            height
            formats {
                small {
                    url
                    width
                    height
                }
            }
        }
    }
`;
